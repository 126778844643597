<template>
    <div class="app-container">
      <el-row class="margin-bottom-20">
        <el-col :lg="16" :xs="16" :offset="0">
          <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID objeto" v-model="searchForm.query"
            v-on:change="handleSearchFilter()" clearable></el-input>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
            icon="el-icon-search">Buscar</el-button>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
            icon="el-icon-delete">Limpiar</el-button>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button
          @click.stop.prevent="handleExport"
          type="success"
          icon="el-icon-bottom"
          size="mini"
          >Exportar</el-button>
        </el-col>
      </el-row>
      <!-- Agrega el select de tipos de evento aquí -->
      <el-row class="margin-bottom-20">
        <el-col :lg="16" :xs="16" :offset="0">
          <el-select v-model="searchForm.type" placeholder="Tipo de evento" @change="handleSearchFilter">
            <el-option
              v-for="item in types"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!---- Paginacion -->
      <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-row>
      <!---- Tabla de eventos ----->
      <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
        highlight-current-row style="width: 100%">
        <el-table-column label="Id object"  fixed="left" width="120" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.object.id) }}</div>
              <span>{{ scope.row.object.id !== undefined ? scope.row.object.id.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Id Usuario" width="120" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.userId) }}</div>
              <span>{{ scope.row.userId !== undefined ? scope.row.userId.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Nombre" width="180" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Email" width="200" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.userEmail }}</span>
          </template>
        </el-table-column>
        <el-table-column label="ID event" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.idStripe) }}</div>
              <span>{{ scope.row.idStripe !== undefined ? scope.row.idStripe.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Object" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.object.object) }}</div>
              <span>{{ scope.row.object.object !== undefined ? scope.row.object.object : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Tipo de evento" align="center" width="290">
          <template slot-scope="scope">
            <span>{{ isNotEmpty(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Estatus" align="center" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 'initialized'" type="primary">{{ scope.row.status }}</el-tag>
            <el-tag v-else-if="scope.row.status == 'completed'" type="success">{{ scope.row.status }}</el-tag>
            <el-tag v-else-if="scope.row.status == 'error'" type="danger">{{ scope.row.status }}</el-tag>
            <el-tag v-else type="info">N/D</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Data Object" type="expand" width="210" align="center">
          <template slot-scope="scope">
            <!-- Verificamos si 'object' es un objeto no vacío -->
            <div v-if="scope.row.object && Object.keys(scope.row.object).length > 0">
              <!-- Tabla para mostrar los datos del objeto -->
              <el-table :data="Object.entries(scope.row.object)" size="mini" border highlight-current-row>
                <el-table-column label="Propiedad" align="center">
                  <template slot-scope="meta">
                    <!-- Mostramos la clave -->
                    <span style="font-weight: 800;">{{ meta.row[0] }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Valor" align="center">
                  <template slot-scope="meta">
                    <!-- Mostramos el valor correspondiente -->
                    <span>{{ meta.row[1] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- Si 'object' está vacío o no es un objeto -->
            <div v-else>
              <span>N/D</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Fecha de Creación" fixed="right" width="230" align="center" >
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | formatDateTime }}</span>
          </template>
        </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { searchEvent, getEvent, getPageSubscription, exportExcelEvents } from '@/api/stripe.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'events-strip',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: '',
        userId: null,
        types: [],
        eventTypeFilter: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: []
    }
  },
  mounted () {
    var idTemp = this.$route.params.paymentId
    if (idTemp) {
      this.searchForm.query = idTemp
    }
    this.handleSearchFilter()
    this.fetchEventTypes()
  },
  methods: {
    isNotEmpty (contain) {
      return contain !== undefined || contain !== '' ? contain : 'N/D'
    },
    async fetchEventTypes () {
      try {
        var formData = new FormData()
        formData.append('attribute', 'type')
        const response = await getEvent(formData)
        if (response.success) {
          this.types = response.data.map(item => item.type) // Mapear a un arreglo de tipos
        } else {
          console.error('Error al obtener tipos de eventos:', response)
        }
      } catch (error) {
        console.error('Error al obtener tipos de eventos:', error)
      }
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await searchEvent(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(item => (item.metadata = [item.metadata]))
          console.log(this.list)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPageSubscription(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPageSubscription(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleExport () {
      this.loading = true
      await exportExcelEvents(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-Eventos-Stripe-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm = {
        query: '',
        userId: null,
        types: [],
        eventTypeFilter: ''
      }
      this.handleSearchFilter()
    }
  }
}
</script>

<style lang="css">

</style>
